import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { useTerminalsState } from "States/Terminals"
import { StationForm, StationFormData } from "../manage/stationForm"
import { useSlackNotifications } from "api/hooks/useSlackNotifications"
import { ACCESS_POINT_STATUSES } from "Utils/gqlRequestTypes/generic"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useGlobalAlert } from "States/globalAlert"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useAccessParentsWithPoints } from "../manage/useAccessParentsWithPoints"
import { trpc } from "Utils/trpc"

export const AddStationModal: React.FC = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { isLoading: isLoadingAccessParents, refetch } = useAccessParentsWithPoints()
	const { setSelectedContainers } = useMultiSelectContext()
	const { setGlobalAlert } = useGlobalAlert()

	const { sendNewAccessParentSlackNotification } = useSlackNotifications()

	const {
		currentTerminal: { id: terminalId },
	} = useTerminalsState()

	const formHandler = useForm<StationFormData>({
		defaultValues: { status: ACCESS_POINT_STATUSES[0] },
	})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const { mutateAsync, isLoading: isSaving } = trpc.accessParents.create.useMutation({
		onSuccess: async modifiedPoint => {
			await refetch()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:accessParentAdded",
			})
			sendNewAccessParentSlackNotification(modifiedPoint.name, modifiedPoint.id)
			setSelectedContainers([])

			const url = getInfrastructureUrl({
				terminalId,
				accessParentId: modifiedPoint.id,
			})
			navigate(url)
			hideModal()
		},
		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const isLoading = isLoadingAccessParents || isSaving

	const onSubmit = handleSubmit(
		(formData: {
			name: string
			status: string
			coordinates: string
			municipality: string
			client: string
		}) => {
			const { name, status, coordinates, municipality, client } = formData

			mutateAsync({
				name,
				status,
				coordinates,
				municipality,
				clientName: client,
				terminalId,
			})
		}
	)

	return (
		<ModalContainer
			title="actions:newAccessParent"
			onConfirmText={"actions:confirm"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoading}
			onConfirmDisabled={!isEmpty(errors) || !isValid || isSaving}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<StationForm {...{ formHandler, onSubmit }} />
		</ModalContainer>
	)
}
