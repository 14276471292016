import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { useTerminalsState } from "States/Terminals"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { useGlobalAlert } from "States/globalAlert"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import FormInput from "components/Form/FormInput"
import { useTrans } from "translations"
import { Dispatch, SetStateAction, useMemo } from "react"
import { ACCESS_POINT_STATUSES, ACTIVE } from "Utils/gqlRequestTypes/generic"
import SelectInput from "components/Form/SelectInput"
import { useAccessPoints } from "../manage/useAccessPoints"
import { trpc } from "Utils/trpc"

type DepotFormData = {
	name: string
	status: string
}

export const AddDepotModal: React.FC<{
	parentId: string
	setSelectedDepotId: Dispatch<SetStateAction<string | undefined>>
}> = ({ parentId, setSelectedDepotId }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { ref: modalRef } = useModal()
	const { isLoadingAll, refetchAll } = useAccessPoints({
		accessParentId: parentId,
	})
	const { setSelectedContainers } = useMultiSelectContext()
	const { setGlobalAlert } = useGlobalAlert()

	const {
		currentTerminal: { id: terminalId },
	} = useTerminalsState()

	const { mutateAsync, isLoading: isSaving } = trpc.depots.create.useMutation({
		onSuccess: async res => {
			await refetchAll()

			setGlobalAlert({
				type: "success",
				message: "systemMessages:groupAdded",
			})
			setSelectedContainers([])
			setSelectedDepotId(res.id)

			const url = getInfrastructureUrl({
				terminalId,
				accessParentId: parentId,
			})
			navigate(url)
			hideModal()
		},

		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const formHandler = useForm<DepotFormData>({
		defaultValues: {
			status: ACTIVE,
		},
	})

	const {
		register,
		control,
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const statusOptions = useMemo(
		() =>
			ACCESS_POINT_STATUSES.map(value => ({
				label: t(`status:${value}`),
				value,
				translate: true,
			})),
		[t]
	)

	const onSubmit = handleSubmit((depotFormData: { name: string; status: string }) => {
		const { name, status } = depotFormData

		mutateAsync({
			name,
			status,
			parentId,
		})
	})

	return (
		<ModalContainer
			title="actions:newGroup"
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingAll || isSaving}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<div className="mb-6 flex flex-col gap-y-5">
				<FormInput
					name="name"
					label={t("formLabels:groupName")}
					register={register}
					error={errors?.name}
					required
				/>
				<SelectInput
					label={t("formLabels:status")}
					name="status"
					options={statusOptions}
					control={control}
					menuPortalTarget={modalRef.current}
					required
				/>
			</div>
		</ModalContainer>
	)
}
