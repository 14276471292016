import { ArrowIcon } from "Icons/Arrow"
import { ComponentProps, FC } from "react"
import Select, { components } from "react-select"
import AsyncSelect from "react-select/async"
import { twMerge } from "tailwind-merge"

type CommonProps = {
	showErrorState?: boolean
	onChange?: (value: any) => void
	async?: boolean // Determines whether to use AsyncSelect or Select
	optionClassName?: (label: string) => string
}

type StyledSelectProps = CommonProps &
	(
		| (Omit<ComponentProps<typeof Select>, "classNames" | "onChange"> & { async?: false })
		| (Omit<ComponentProps<typeof AsyncSelect>, "classNames" | "onChange"> & { async: true })
	)

/**
 * A styled select component to be used outside of forms. For use in forms, use the `SelectInput` component.
 */
export const StyledSelect: FC<StyledSelectProps> = props => {
	const { showErrorState, async, optionClassName, ...rest } = props

	const Input = (props: any) => <components.Input {...props} autoComplete="off" />
	const SelectComponent = async ? AsyncSelect : Select

	return (
		<SelectComponent
			{...rest}
			unstyled
			classNames={{
				control: ({ isFocused, isDisabled }) =>
					twMerge(
						"text-sm border border-black rounded-none box-border h-10 py-2 px-3",
						isFocused && "border-2 shadow-black",
						isDisabled && "bg-grey1 text-grey6",
						showErrorState && "border border-carrotRed bg-lightPeach focus:outline-carrotRed"
					),
				indicatorSeparator: () => "hidden",
				menu: ({ placement }) =>
					twMerge("border-grey2 border rounded-none", placement === "bottom" ? "mt-1" : "mb-1"),
				option: ({ isDisabled, isSelected, label }) =>
					twMerge(
						"bg-white !text-sm text-black p-2 hover:bg-grey1",
						isDisabled && "cursor-not-allowed bg-grey1 text-grey6",
						isSelected && "bg-grey2",
						optionClassName?.(label)
					),
				placeholder: () => "text-sm text-grey4",
				menuList: () => "divide-y bg-white",
			}}
			components={{
				DropdownIndicator: ({ isFocused }) => (
					<ArrowIcon className={twMerge("h-3 w-3 ml-2", isFocused && "transform rotate-180")} />
				),
				Input,
			}}
		/>
	)
}
