import { trpc } from "Utils/trpc"
import { useTerminalsState } from "../../States/Terminals"
import { useEffect } from "react"
import { useConfig } from "./useConfig"
import { getPermittedTerminals } from "Utils/authUtils"

/**
 * Use terminals hook, used to manage terminals. The underlying state is managed by useTerminalsState.
 */
export const useTerminals = () => {
	const { isMWM } = useConfig()
	const { setTerminalState } = useTerminalsState()

	const { data, isLoading: isLoadingTerminals, refetch } = trpc.terminals.getAll.useQuery()

	useEffect(() => {
		const { allTerminals, terminals } = getPermittedTerminals(data, isMWM)
		setTerminalState({ allTerminals, terminals })
	}, [data, setTerminalState, isMWM])

	const refetchTerminals = async () => {
		await refetch()
		const { allTerminals, terminals } = getPermittedTerminals(data, isMWM)
		setTerminalState({ allTerminals, terminals })
	}

	const { mutateAsync: updateTerminal, isLoading: isUpdatingTerminal } =
		trpc.accessPoints.update.useMutation({
			onSuccess: async () => {
				await refetchTerminals()
			},
		})

	return {
		refetchTerminals,
		isLoadingTerminals,
		updateTerminal,
		isUpdatingTerminal,
	}
}
