import FormInput from "components/Form/FormInput"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty, groupBy } from "lodash"
import { FC, useCallback, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"

import { trpc } from "Utils/trpc"
import { AccessParentContainerData } from "../functions"
import {
	SmartInfrastructureStatus,
	SmartIntegration,
	validationMax,
	validationMin,
	validationPatterns,
	validIntegrationCombinations,
} from "Utils/smartInfrastructure"
import InfoPopup from "components/InfoPopup/InfoPopup"
import { useGlobalAlert } from "States/globalAlert"
import { getTokenData } from "Utils/cookie"
import { StyledSelect } from "components/StyledSelect"
import { useAccessPoints } from "../manage/useAccessPoints"

type SmartFunctionalityFormData = {
	smartCapability: string
	manufacturer: string
	smartProperties: { value: string }[]
}

export const SmartFunctionalityModal: FC<{
	accessParentId: string
	container: AccessParentContainerData
}> = ({ container, accessParentId }) => {
	const { hideModal } = useModal()
	const { t } = useTrans()
	const containerRef = useRef(null)
	const { smartIntegrations } = useCommonEntitiesStore()
	const { currentTerminal } = useTerminalsState()
	const { setGlobalAlert } = useGlobalAlert()
	const { refetch } = useAccessPoints({ accessParentId })
	const [selectedCapabilityId, setSelectedCapabilityId] = useState<string | undefined>(undefined)
	const [selectedManufacturerId, setSelectedManufacturerId] = useState<string | undefined>(
		undefined
	)

	// Assumes that there is only one smart integration per container when this modal is open.
	// This rule is enforced by the canAddSmartFunctionality check in ContainerSmartCapabilities.tsx
	const currentSmartIntegration: SmartIntegration = useMemo(
		() => JSON.parse(container?.smartIntegrations ?? "[]")[0],
		[container]
	)

	const authUserEmail = useMemo(() => getTokenData()?.["https://auth0.carrot.tech/email"], [])

	const {
		register,
		formState: { errors, isValid: areFieldsValid },
		handleSubmit,
		reset,
	} = useForm<SmartFunctionalityFormData>({
		mode: "onTouched",
		defaultValues: {
			smartProperties: [],
		},
	})

	// Capabilities grouped by type
	const groupedSmartCapabilities = useMemo(
		() => groupBy(smartIntegrations ?? [], "type"),
		[smartIntegrations]
	)

	const smartCapabilityOptions = useMemo(
		() =>
			Object.keys(groupedSmartCapabilities).map(sc => ({
				label: t(`entities:smart-tech-${sc}`),
				value: sc,
			})),
		[groupedSmartCapabilities, t]
	)

	const manufacturerOptions = useMemo(
		() =>
			(groupedSmartCapabilities ?? {})[selectedCapabilityId ?? ""]?.map(sc => ({
				label: sc.name,
				value: sc._id,
			})),
		[groupedSmartCapabilities, selectedCapabilityId]
	)

	const selectedCapability = useMemo(
		() => groupedSmartCapabilities[selectedCapabilityId ?? ""],
		[selectedCapabilityId, groupedSmartCapabilities]
	)

	const selectedManufacturer = useMemo(
		() => selectedCapability?.find(sc => sc._id === selectedManufacturerId),
		[selectedCapability, selectedManufacturerId]
	)

	const containerSmartPropertyFields = useMemo(
		() => selectedCapability?.find(sc => sc._id === selectedManufacturerId)?.fields ?? [],
		[selectedCapability, selectedManufacturerId]
	)

	const { mutate, isLoading } = trpc.upsertSmartInfrastructure.useMutation({
		onSuccess: () => {
			setGlobalAlert({
				type: "success",
				message: "systemMessages:technologyAdded",
				instructions: "systemMessages:technologyAddedDetails",
				instructionVariables: { email: authUserEmail ?? "" },
			})
			refetch()
			hideModal()
		},
	})

	const onConfirm = handleSubmit((containerFormData: SmartFunctionalityFormData) => {
		const smartIntegrations = [
			currentSmartIntegration,
			{
				manufacturer: selectedManufacturer!.name,
				capabilityType: selectedCapabilityId!,
				status: SmartInfrastructureStatus.REQUESTED,
				fields: containerSmartPropertyFields.map(({ label, title }, index) => ({
					key: label ?? title,
					value: containerFormData.smartProperties[index].value,
				})),
			},
		].filter(Boolean)
		const url = new URL(window.location.href)
		url.searchParams.set("terminal-id", currentTerminal.id)
		mutate({ containerId: container.id, sendEmail: true, url: url.toString(), smartIntegrations })
	})

	const isValid = areFieldsValid && !!selectedCapabilityId && !!selectedManufacturerId

	const patternError = useCallback(
		(validationType: keyof typeof validationPatterns) => {
			switch (validationType) {
				case "uint32":
					return t("errors:uint32InvalidValue")
				case "uuid":
					return t("errors:uuidInvalidValue")
				case "non-empty-string":
					return t("errors:nonEmptyStringInvalidValue")
				case "15-digits":
					return t("errors:15digitsInvalidValue")
			}
		},
		[t]
	)

	const setCapability = useCallback(
		(capabilityId?: string) => {
			setSelectedCapabilityId(capabilityId)
			setSelectedManufacturerId(undefined)
			reset()
		},
		[setSelectedCapabilityId, setSelectedManufacturerId, reset]
	)

	const setManufacturer = useCallback(
		(manufacturerId?: string) => {
			setSelectedManufacturerId(manufacturerId)
			reset()
		},
		[setSelectedManufacturerId, reset]
	)

	return (
		<ModalContainer
			title="actions:addSmartCapabilities"
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className={"w-3/4 sm:w-1/2 lg:w-1/3"}
			onCancel={hideModal}
			{...{ onConfirm }}
			contentContainerRef={containerRef}
			onConfirmLoading={isLoading}
		>
			<div className="mb-4 text-sm">{t("hints:addSmartCapabilitiesHint")}</div>
			<div className="flex-col gap-4 min-h-[480px]">
				<div>
					<div className="mb-1">
						<label className="font-dmSans text-xs font-medium text-black" htmlFor="smartCapability">
							{t("formLabels:smartCapability")} *{" "}
							<InfoPopup
								id="chooseTechnology"
								text={t("hints:chooseTechnology")}
								width="180px"
								size={15}
								className="inline-flex align-bottom mb-1"
							/>
						</label>
					</div>
					<StyledSelect
						name="smartCapability"
						options={smartCapabilityOptions}
						placeholder={t("formLabels:chooseTechnology")}
						isOptionDisabled={(option: any) =>
							!!currentSmartIntegration?.capabilityType &&
							!validIntegrationCombinations[currentSmartIntegration.capabilityType]?.includes(
								option.value
							)
						}
						onChange={val => setCapability(val?.value)}
						required
					/>
				</div>
				{selectedCapabilityId && (
					<div>
						<div className="mb-1">
							<label className="font-dmSans text-xs font-medium text-black" htmlFor="manufacturer">
								{t("formLabels:manufacturer")} *
							</label>
						</div>
						<StyledSelect
							name="manufacturer"
							options={manufacturerOptions}
							placeholder={t("formLabels:chooseManufacturer")}
							value={manufacturerOptions?.find(mo => mo.value === selectedManufacturerId) ?? null}
							onChange={val => setManufacturer(val?.value)}
							required
						/>
					</div>
				)}
				{containerSmartPropertyFields.map((smartProperty, index) => (
					<FormInput
						key={`smartProperty_${smartProperty.title}_${index}`}
						name={`smartProperties.${index}.value`}
						label={smartProperty.label ?? smartProperty.title}
						register={register}
						regexPattern={validationPatterns[smartProperty.validation]}
						min={validationMin[smartProperty.validation]}
						max={validationMax[smartProperty.validation]}
						error={errors?.smartProperties?.[index]?.value}
						patternError={patternError(smartProperty.validation)}
						required
					/>
				))}
			</div>
		</ModalContainer>
	)
}
