import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Tabs } from "components/Tabs"

import { Page } from "components/Page"
import { Client } from "./client"
import { Building } from "./building"
import { Dashboard } from "./dashboard"
import { useModal } from "Contexts"
import { DiscardModal } from "components/modalContainer"
import { useConfig } from "api/hooks/useConfig"
import { WasteStreams } from "./wasteStreams"
import { useSearchParams } from "react-router-dom"
import { WasteStreamsBuilding } from "./wasteStreamsBuilding"
import classNames from "classnames"
import { isCarrotAdmin } from "Utils/authUtils"
import { ActionReport } from "./actionReport"
import { Payments } from "./payments"

interface ConfigurationProps {}

const MWM_TABS = [{ title: "configLabels:client" }]

export const getRealEstateTabs = (isCarrotAdmin: boolean) => {
	const tabs = []

	if (isCarrotAdmin) {
		tabs.push(
			{ title: "configLabels:client" },
			{ title: "configLabels:wasteStreams" },
			{ title: "configLabels:building" }
		)
	}

	tabs.push(
		{ title: "configLabels:actionReport" },
		{ title: "configLabels:dashboard" },
		{ title: "configLabels:buildingWasteStreams" }
	)

	if (isCarrotAdmin) {
		tabs.push({ title: "configLabels:payments" })
	}

	return tabs
}

export const Configuration: FC<ConfigurationProps> = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [tabIndex, setTabIndex] = useState<number>(Number(searchParams.get("tab") ?? 0))
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
	const { showModal, hideModal } = useModal()
	const { isMWM } = useConfig()

	useEffect(() => {
		if (isMWM && tabIndex !== 0) {
			setTabIndex(0)
		}
	}, [tabIndex, isMWM, setTabIndex])

	const switchTab = useCallback(
		(tab: number) => {
			if (hasUnsavedChanges) {
				showModal(
					<DiscardModal
						onCancel={() => hideModal()}
						onConfirm={() => {
							setTabIndex(tab)
							hideModal()
							setHasUnsavedChanges(false)
						}}
					/>
				)
			} else {
				setTabIndex(tab)
				setSearchParams({ tab: tab.toString() })
			}
		},
		[setTabIndex, hasUnsavedChanges, showModal, hideModal, setSearchParams]
	)

	const REAL_ESTATE_TABS = useMemo(() => getRealEstateTabs(isCarrotAdmin()), [])

	const TABS = useMemo(() => (isMWM ? MWM_TABS : REAL_ESTATE_TABS), [isMWM, REAL_ESTATE_TABS])

	const tabView = useMemo(() => {
		if (!isCarrotAdmin() && !isMWM) {
			switch (tabIndex) {
				case 0:
					return <ActionReport />
				case 1:
					return (
						<Dashboard
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				case 2:
					return (
						<WasteStreamsBuilding
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				default:
					return null
			}
		} else if (!isMWM) {
			switch (tabIndex) {
				case 0:
					return (
						<Client
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				case 1:
					return (
						<WasteStreams
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				case 2:
					return (
						<Building
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				case 3:
					return <ActionReport />
				case 4:
					return (
						<Dashboard
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				case 5:
					return (
						<WasteStreamsBuilding
							setHasUnsavedChanges={setHasUnsavedChanges}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					)
				case 6:
					return (
						<Payments
							hasUnsavedChanges={hasUnsavedChanges}
							setHasUnsavedChanges={setHasUnsavedChanges}
						/>
					)
				default:
					return null
			}
		} else {
			return (
				<Client setHasUnsavedChanges={setHasUnsavedChanges} hasUnsavedChanges={hasUnsavedChanges} />
			)
		}
	}, [tabIndex, hasUnsavedChanges, setHasUnsavedChanges, isMWM])

	const tabHasStickySection = useMemo(() => tabIndex === 1 || tabIndex === 4, [tabIndex])

	return (
		<Page title="configLabels:settings">
			<div className={classNames("bg-white", tabHasStickySection ? "pt-4" : "py-4")}>
				<div className="px-4 pb-3 mb-4 border-b-2 border-gray1">
					<Tabs tabs={TABS} activeTab={tabIndex} setActiveTab={switchTab} />
				</div>
				<div className="px-4">{tabView}</div>
			</div>
		</Page>
	)
}
