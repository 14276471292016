import classNames from "classnames"
import { useAuth0 } from "Contexts"
import ArrowDown from "Icons/ArrowDown"
import User from "Icons/User"
import { orderBy } from "lodash"
import { useCallback, useMemo, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import useClickoutside from "Utils/useClickoutside"
import { LanguageSelection } from "./LanguageSelection"
import { useMultiSelectContext } from "components/pageCards/multiListTable/multiSelectContext"
import { isCarrotAdmin } from "Utils/authUtils"
import { useConfig } from "api/hooks/useConfig"
import { isMultiTenant } from "Configs/config"
import { trpc } from "Utils/trpc"

export const UserButton = () => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const { user, logout } = useAuth0()!
	const { t } = useTrans()
	const containerWrapper = useRef(null)
	const { isMWM } = useConfig()
	const { currentTerminal } = useTerminalsState()
	const { data: sites } = trpc.config.getSites.useQuery(undefined, {
		enabled: isMultiTenant,
	})

	useClickoutside(containerWrapper, () => setOpen(false))

	const settingsVisible = useMemo(() => !isMWM || isCarrotAdmin(), [isMWM])

	const switchClient = useCallback(() => {
		localStorage.removeItem("selectedSite")
		document.location.href = "/"
	}, [])

	return (
		<div className="relative block text-left" ref={containerWrapper}>
			<div className="flex">
				<button
					type="button"
					className="inline-flex bg-white p-0 hover:bg-white focus:outline-none active:bg-white"
					id="menu-button"
					aria-expanded="true"
					aria-haspopup="true"
					onClick={() => setOpen(prevState => !prevState)}
				>
					{!isMWM && <div className="pr-4">{currentTerminal?.name}</div>}
					<User />
				</button>
			</div>
			<div
				className={classNames(
					"visible absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white ring-1 ring-grey1 focus:outline-none",
					!isOpen && "invisible"
				)}
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="menu-button"
				tabIndex={-1}
			>
				<div role="none" className="divide-y divide-grey2">
					<div
						className="block px-4 py-3.5 text-sm text-grey4"
						role="menuitem"
						tabIndex={-1}
						id="menu-item-0"
					>
						<span className="text-sm text-grey4">{user.email}</span>
					</div>
					{!isMWM && <TerminalSelection />}
					<LanguageSelection />
					{isCarrotAdmin() && (
						<Link
							className="block w-full bg-white px-4 py-3.5 text-left text-sm font-normal hover:bg-neutral-100 active:bg-neutral-100"
							to={`/global-settings`}
							onClick={() => setOpen(false)}
						>
							{t("configLabels:globalSettings")}
						</Link>
					)}
					{settingsVisible && (
						<Link
							className="block w-full bg-white px-4 py-3.5 text-left text-sm font-normal hover:bg-neutral-100 active:bg-neutral-100"
							to={`/settings`}
							onClick={() => setOpen(false)}
						>
							{t("configLabels:settings")}
						</Link>
					)}
					{isMultiTenant && (sites?.length ?? 1) > 1 && (
						<div>
							<button
								className="block w-full bg-white px-4 py-3.5 text-left text-sm font-normal text-carrotOrange hover:bg-white active:bg-white"
								role="menuitem"
								tabIndex={-1}
								id="menu-item-1"
								onClick={switchClient}
							>
								{t("actions:switchClient")}
							</button>
						</div>
					)}
					<div>
						<button
							className="block w-full bg-white px-4 py-3.5 text-left text-sm font-normal text-carrotRed hover:bg-white active:bg-white"
							role="menuitem"
							tabIndex={-1}
							id="menu-item-1"
							onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
						>
							{t("actions:logout")}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UserButton

const TerminalSelection = () => {
	const [active, setActive] = useState(false)
	const { terminals, currentTerminal, setTerminalState } = useTerminalsState()
	const { t } = useTrans()
	const navigate = useNavigate()
	const { setSelectedIds } = useMultiSelectContext()

	const containerWrapper = useRef(null)
	useClickoutside(containerWrapper, () => setActive(false))

	if (!currentTerminal.id) return null

	const onChangeTerminal = (terminal: (typeof terminals)[number]) => {
		setTerminalState({
			currentTerminal: terminal,
		})
		setActive(false)
		setSelectedIds([])

		// explicitly rerout on manage infrastructure
		if (window.location.pathname.includes("infrastructure/manage"))
			navigate({
				pathname: "/infrastructure/manage",
			})
		// remove search params when changing real estate
		navigate({ pathname: window.location.pathname })
	}

	const sortedRealEstates = orderBy(terminals, "name")

	if (sortedRealEstates.length === 1) return null

	return (
		<div className="relative">
			<div
				className="flex cursor-pointer items-center justify-between py-2.5 px-4 text-sm"
				onClick={() => {
					setActive(_active => !_active)
				}}
			>
				<div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
					{currentTerminal.name}
				</div>
				<div>
					<ArrowDown className="-rotate-90" />
				</div>
			</div>
			{active && (
				<div className="relative w-0" ref={containerWrapper}>
					<div className="absolute -top-10 right-0 z-20 w-64 border bg-white py-6 px-3 pb-3">
						<div className="flex cursor-pointer justify-between gap-2">
							<div className="flex gap-2">
								<div className="whitespace-nowrap font-bold">{t("actions:selectBuilding")}</div>
							</div>
						</div>
						<div className="flex-col gap-0.5 pt-6">
							<div className="max-h-96 divide-y overflow-auto text-sm leading-tight">
								{sortedRealEstates.map((realEstate, index) => {
									const isSelected = realEstate.id === currentTerminal.id
									return (
										<div
											className={classNames(
												"flex cursor-pointer select-none justify-between p-3",
												"hover:bg-grey2",
												isSelected && "bg-grey3"
											)}
											key={`terminalOption_${index}`}
											onClick={() => onChangeTerminal(realEstate)}
										>
											{realEstate.name}
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
