import { useTerminalsState } from "States/Terminals"
import { trpc } from "Utils/trpc"
import { orderBy } from "lodash"
import { useMemo } from "react"

export const useAccessParentsWithPoints = (overrideTerminalId?: string) => {
	const { currentTerminal } = useTerminalsState()

	const terminalId = overrideTerminalId ?? currentTerminal.id

	const {
		data: unsortedAccessParents,
		isLoading,
		isError,
		refetch,
	} = trpc.accessParents.getAllWithPointsByParentId.useQuery(
		{
			terminalId,
		},
		{ enabled: !!terminalId }
	)

	const accessParents = useMemo(
		() => orderBy(unsortedAccessParents, "name"),
		[unsortedAccessParents]
	)

	const getAccessParent = (accessParentId?: string) =>
		accessParents?.find(({ id }) => id === accessParentId)

	return {
		accessParents,
		isLoading,
		isError,
		getAccessParent,
		refetch,
	}
}
