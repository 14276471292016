import { useTerminals } from "api/hooks/useTerminals"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { FC, useCallback } from "react"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"

interface DeleteTerminalModalProps {
	terminalId: string
	terminalName: string
	onSuccess?: (newTerminalId: string) => void
}

export const DeleteTerminalModal: FC<DeleteTerminalModalProps> = ({
	terminalId,
	terminalName,
	onSuccess,
}) => {
	const { hideModal } = useModal()
	const { t } = useTrans()
	const { refetchTerminals, isLoadingTerminals } = useTerminals()

	const { mutateAsync: deletePoint, isLoading: isDeletingPoint } =
		trpc.accessPoints.delete.useMutation()

	const { setGlobalAlert } = useGlobalAlert()
	const { terminals, setCurrentTerminal } = useTerminalsState()

	const onConfirm = useCallback(() => {
		deletePoint(
			{ id: terminalId, deleteTerminalConfig: true },
			{
				onSuccess: async () => {
					setGlobalAlert({
						type: "success",
						message: "systemMessages:terminalDeleted",
					})
					await refetchTerminals()
					hideModal()

					// Find the first terminal that is not the one we just deleted
					const newTerminal = terminals.find(t => t.id !== terminalId)
					if (!newTerminal) return

					setCurrentTerminal(newTerminal)
					onSuccess?.(newTerminal.id)
				},
				onError: error => {
					console.error(error)
					setGlobalAlert({
						type: "error",
						message: "errors:unknown",
					})
				},
			}
		)
	}, [
		deletePoint,
		terminalId,
		setGlobalAlert,
		refetchTerminals,
		hideModal,
		terminals,
		setCurrentTerminal,
		onSuccess,
	])

	return (
		<ModalContainer
			title="actions:deleteTerminal"
			onConfirm={onConfirm}
			onCancel={hideModal}
			onConfirmLoading={isDeletingPoint || isLoadingTerminals}
			onConfirmText="actions:confirm"
		>
			<div className="pt-3">
				<p>{t("hints:areYouSureDelete", { name: terminalName })}</p>
			</div>
		</ModalContainer>
	)
}
