import { trpc } from "Utils/trpc"
import { useSites } from "api/hooks/useSites"
import React, { useEffect } from "react"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { useTrans } from "translations"
import { capitalize } from "lodash"

const SitePicker: React.FC = () => {
	const { setSelectedSite } = useSites()
	const { t } = useTrans()
	const { data: sites, isLoading } = trpc.config.getSites.useQuery()

	useEffect(() => {
		const urlSite = window.location.pathname.split("/")?.[1]
		const siteFromUrl = sites?.find(s => s.siteName === urlSite)
		if (siteFromUrl) {
			setSelectedSite(siteFromUrl)
		} else if (sites?.length === 1 && sites[0]) {
			setSelectedSite(sites[0])
		}
	}, [sites, setSelectedSite])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-[300px]">
				<LoadingIndicator />
			</div>
		)
	}

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="text-center">
				<h1 className="text-2xl font-bold font-signifier mb-4">{t("genericLabels:selectSite")}</h1>
				<p className="mb-6 font-dmSans">{t("hints:youHaveMultipleSites")}</p>
				<ul className="space-y-4">
					{sites
						?.filter(s => !!s.siteName && !!s.displayName)
						.map(site => (
							<li
								key={site.id}
								className="border border-gray-300 p-4 shadow-sm hover:shadow-md hover:border-gray-400 transition"
								onClick={() => setSelectedSite(site)}
							>
								<h2 className="text-lg font-dmSans font-semibold">
									{capitalize(site.displayName!)}
								</h2>
							</li>
						))}
				</ul>
			</div>
		</div>
	)
}

export default SitePicker
