import { useQuery } from "@tanstack/react-query"
import { fetchRequest } from "../request"
import { getDatawarehousePayload, parseDatawarehouseInput } from "./payloads"
import { DataWareHouseInput, DataWareHouseInputGuard, EndpointResponseGuard } from "./types"
import { getTokenFromCookie } from "Utils/authUtils"
import { useSites } from "api/hooks/useSites"
import { useParams } from "react-router-dom"

/** Hook for http request against datawarehouse */
export const useDataWareHouse = <T extends DataWareHouseInput>(
	input: DataWareHouseInputGuard<T>,
	options?: Parameters<typeof useQuery<any>>["2"]
) => {
	const { client } = useParams()
	const { siteName } = useSites()
	type Response = EndpointResponseGuard<typeof input>
	const headers = {
		headers: {
			authorization: `Bearer ${getTokenFromCookie()}`,
		},
	}

	const parsedInput = parseDatawarehouseInput(input, client || siteName)
	const { url, cacheKey } = getDatawarehousePayload(parsedInput)

	const datawarehouseResponse = useQuery<Response>(
		cacheKey,
		() => fetchRequest<Response>(url, headers),
		options
	)

	return datawarehouseResponse
}
