import { Terminal as ApiTerminal } from "admin-client-server/src/coreApi/models/Common"
import create from "zustand"
import { combine } from "zustand/middleware"

export type Terminal = ApiTerminal[][number] & {
	accessToken: string
	wasteTypeClassificationSystemId?: string
	children?: { id: string }[]
}

export const useTerminalsState = create(
	combine(
		{
			currentTerminal: {} as Terminal,
			/** List of active terminals */
			terminals: [] as Terminal[],
			/** List of all terminals, including inactive ones. Used in f.ex. Settings */
			allTerminals: [] as Terminal[],
		},
		(set, get) => ({
			setCurrentTerminal: (terminal: Terminal) => {
				if (terminal?.id) localStorage.setItem("terminalId", terminal.id)
				set(prevState => ({ ...prevState, currentTerminal: terminal }))
			},
			setTerminalState: (state: Partial<ReturnType<typeof get>>) => {
				if (state.currentTerminal?.id) localStorage.setItem("terminalId", state.currentTerminal.id)
				return set(prevState => ({ ...prevState, ...state }))
			},
		})
	)
)
