import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { TerminalForm, TerminalFormData } from "./TerminalForm"
import { useTerminals } from "api/hooks/useTerminals"
import { useNavigate } from "react-router-dom"
import { getInfrastructureUrl } from "Utils/getInfrastructureUrl"
import { useGlobalAlert } from "States/globalAlert"
import { trpc } from "Utils/trpc"

export const CreateTerminalModal = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { refetchTerminals, isLoadingTerminals } = useTerminals()
	const { setGlobalAlert } = useGlobalAlert()

	const formHandler = useForm<TerminalFormData>({})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const { mutate, isLoading: isSaving } = trpc.terminals.create.useMutation({
		onSuccess: async ({ id }) => {
			await refetchTerminals()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:terminalAdded",
			})
			hideModal()

			const url = getInfrastructureUrl({
				terminalId: id,
			})
			navigate(url)
		},
	})

	const isLoading = isSaving || isLoadingTerminals

	const setupTerminal = (terminalFormData: TerminalFormData) => {
		const terminalData = {
			name: terminalFormData.name,
			status: "ACTIVE",
		}

		mutate(terminalData)
	}

	const onSubmit = handleSubmit(terminalFormData => {
		setupTerminal(terminalFormData)
	})

	return (
		<ModalContainer
			title="actions:createTerminal"
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoading}
			onCancel={hideModal}
			onConfirmDisabled={!isEmpty(errors) || !isValid}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<TerminalForm formHandler={formHandler} />
		</ModalContainer>
	)
}
